
import getCompanyShoppingLists from '@/queries/getCompanyShoppingLists.graphql';
import getCustomerShoppingLists from '@/queries/getCustomerShoppingLists.graphql';
import getCompanyShoppingListsData from '@/queries/getCompanyShoppingListsData.graphql';
import getCustomerShoppingListsData from '@/queries/getCustomerShoppingListsData.graphql';
import updateCompanyShoppingList from '@/queries/mutations/updateCompanyShoppingList.graphql';
import updateShoppingList from '@/queries/mutations/updateShoppingList.graphql';
import addCompanyShoppingList from '@/queries/mutations/addCompanyShoppingList.graphql';
import addShoppingList from '@/queries/mutations/addShoppingList.graphql';

export default {
    props: {
        products: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            companyWishlists: [],
            customerWishlists: [],
            wishlistType: null,
            errors: [],
            dialogOpened: false,
            selectedWishlist: null,
            createNew: false,
            newWishlistName: '',
            productExistsCompany: null,
            productExistsCustomer: null,
        };
    },
    async fetch() {
        if (!this.$store.state.auth.user.isAdmin) {
            this.wishlistType = 'personal';
        }

        if (!this.$store.state.auth.loggedIn) {
            return;
        }

        const {
            data: { company },
        } = await this.$apollo.query({
            query: getCompanyShoppingListsData,
            fetchPolicy: 'no-cache',
        });

        if (company?.shoppingLists?.items) {
            this.companyWishlists = [...company.shoppingLists.items].map((wishlist) => this.removeUnavailableProductsFromWishlist(wishlist));
        } else {
            this.companyWishlists = [];
        }

        const {
            data: { customer },
        } = await this.$apollo.query({
            query: getCustomerShoppingListsData,
            fetchPolicy: 'no-cache',
        });

        if (customer?.shoppingLists?.items) {
            this.customerWishlists = [...customer.shoppingLists.items].map((wishlist) => this.removeUnavailableProductsFromWishlist(wishlist));
        } else {
            this.customerWishlists = [];
        }

        await this.loadExistingWishlists();
    },
    computed: {
        wishlistOptions() {
            const options = [...(this.wishlistType === 'company' ? this.companyWishlists : this.customerWishlists)].map((wishlist) => {
                return {
                    name: `wishlist-${wishlist.uid}`,
                    label: wishlist.title,
                    value: wishlist.uid,
                };
            });

            options.unshift({
                name: 'new-wishlist',
                label: this.$t('account.wishlist.dialog.additem.newlist'),
                value: null,
            });

            return options;
        },
        addToWishlistDisabled() {
            if (this.disabled || !this.products.length) {
                return true;
            }

            if (this.createNew) {
                return false;
            }

            if (!this.selectedWishlist) {
                return true;
            }

            return false;
        },
    },
    methods: {
        async loadExistingWishlists() {
            if (!this.$store.state.auth.loggedIn) {
                return;
            }

            if (!this.products.length) {
                this.productExistsCompany = null;
                this.productExistsCustomer = null;
            } else {
                const productUids = this.products.map((item) => item.product.uid);
                const existingCompanyWishlists = [];
                for (const productUid of productUids) {
                    const {
                        data: {
                            company: {
                                shoppingLists: { items },
                            },
                        },
                    } = await this.$apollo.query({
                        query: getCompanyShoppingListsData,
                        fetchPolicy: 'no-cache',
                        variables: {
                            filter: {
                                product_uid: {
                                    eq: productUid,
                                },
                            },
                        },
                    });

                    if (items) {
                        existingCompanyWishlists.push(...items);
                    }
                }

                if (existingCompanyWishlists && existingCompanyWishlists.length) {
                    this.productExistsCompany = Array.from(new Set(existingCompanyWishlists.map((wishlist) => wishlist.title)));
                } else {
                    this.productExistsCompany = null;
                }

                const existingCustomerWishlists = [];
                for (const productUid of productUids) {
                    const {
                        data: {
                            customer: {
                                shoppingLists: { items },
                            },
                        },
                    } = await this.$apollo.query({
                        query: getCustomerShoppingListsData,
                        fetchPolicy: 'no-cache',
                        variables: {
                            filter: {
                                product_uid: {
                                    eq: productUid,
                                },
                            },
                        },
                    });

                    if (items) {
                        existingCustomerWishlists.push(...items);
                    }
                }

                if (existingCustomerWishlists && existingCustomerWishlists.length) {
                    this.productExistsCustomer = Array.from(new Set(existingCustomerWishlists.map((wishlist) => wishlist.title)));
                } else {
                    this.productExistsCustomer = null;
                }
            }
        },
        async showDialog() {
            if (this.disabled) {
                return;
            }

            if (this.$store.state.wishlist.loading) {
                return;
            }

            if (!this.$store.state.auth.loggedIn) {
                this.$notify.error(this.$t('account.wishlist.loggedout'));

                return;
            }

            await this.loadExistingWishlists();
            this.wishlistType = this.$store.state.auth.user.isAdmin ? null : 'personal';
            this.dialogOpened = true;
        },
        closeDialog() {
            this.dialogOpened = false;
            this.newWishlistName = '';
            this.createNew = false;
        },
        selectWishlist(option) {
            if (option.value === null) {
                this.createNew = true;
                this.selectedWishlist = null;
            } else {
                this.createNew = false;
                const wishlist =
                    this.wishlistType === 'company'
                        ? this.companyWishlists.find((wishlist) => wishlist.uid === option.value)
                        : this.customerWishlists.find((wishlist) => wishlist.uid === option.value);
                this.selectedWishlist = wishlist;
            }
        },
        async processAdding() {
            if (this.disabled) {
                return;
            }

            if (this.$store.state.wishlist.loading) {
                return;
            }

            if (!this.$store.state.auth.loggedIn) {
                this.$notify.error(this.$t('account.wishlist.loggedout'));

                return;
            }

            if (!this.createNew && !this.selectedWishlist) {
                return;
            }

            if (this.createNew && !this.newWishlistName.length) {
                this.$notify.error(this.$t('account.wishlist.dialog.additem.entername'));

                return;
            }

            if (this.createNew) {
                await this.createNewWishlist();
            } else {
                await this.addProductsToWishlist(this.selectedWishlist);
            }
        },
        async addProductsToWishlist(wishlist) {
            let currentWishlist = null;

            if (this.wishlistType === 'company') {
                const {
                    data: {
                        company: {
                            shoppingLists: { items },
                        },
                    },
                } = await this.$apollo.query({
                    query: getCompanyShoppingLists,
                    fetchPolicy: 'no-cache',
                    variables: {
                        filter: {
                            uid: {
                                eq: wishlist.uid,
                            },
                        },
                    },
                });

                if (items && items[0]) {
                    currentWishlist = this.removeUnavailableProductsFromWishlist(items[0]);
                }
            } else {
                const {
                    data: {
                        customer: {
                            shoppingLists: { items },
                        },
                    },
                } = await this.$apollo.query({
                    query: getCustomerShoppingLists,
                    fetchPolicy: 'no-cache',
                    variables: {
                        filter: {
                            uid: {
                                eq: wishlist.uid,
                            },
                        },
                    },
                });

                if (items && items[0]) {
                    currentWishlist = this.removeUnavailableProductsFromWishlist(items[0]);
                }
            }

            const wishlistItems =
                currentWishlist && currentWishlist.items
                    ? currentWishlist.items.map((wishlistItem) => {
                          return {
                              default_qty: wishlistItem.default_qty,
                              product_uid: wishlistItem.product.uid,
                          };
                      })
                    : [];
            this.products.forEach((item) => {
                if (wishlistItems.map((wishlistItem) => wishlistItem.product_uid).includes(item.product.uid)) {
                    for (let i = 0; i < wishlistItems.length; i++) {
                        if (wishlistItems[i].product_uid === item.product.uid) {
                            wishlistItems[i].default_qty = Number(wishlistItems[i].default_qty) + Number(item.qty);
                        }
                    }
                } else {
                    wishlistItems.push({
                        default_qty: item.qty,
                        product_uid: item.product.uid,
                    });
                }
            });

            const payload = {
                shoppinglist_uid: wishlist.uid,
                shoppinglist_items: wishlistItems,
                title: wishlist.title,
            };

            this.errors = [];

            const { data, errors } = await this.$apollo.mutate({
                mutation: this.wishlistType === 'company' ? updateCompanyShoppingList : updateShoppingList,
                variables: {
                    input: payload,
                },
            });

            if (errors) {
                this.errors = errors;
                this.$notify.error(errors);
            }

            if (this.wishlistType === 'company' ? data.updateCompanyShoppingList.success : data.updateShoppingList.success) {
                this.$notify.success(this.$t('account.wishlist.dialog.additem.success'));
            }

            this.closeDialog();
        },

        async createNewWishlist() {
            const payload = {
                title: this.newWishlistName,
            };
            this.errors = [];
            const { data, errors } = await this.$apollo.mutate({
                mutation: this.wishlistType === 'company' ? addCompanyShoppingList : addShoppingList,
                variables: {
                    input: payload,
                },
            });

            if (errors) {
                this.errors = errors;
                this.$notify.error(errors);
            }

            if (this.wishlistType === 'company' ? data.addCompanyShoppingList.success : data.addShoppingList.success) {
                await this.addProductsToWishlist(
                    this.wishlistType === 'company' ? data.addCompanyShoppingList.shoppinglist : data.addShoppingList.shoppinglist,
                );
            }

            await this.$store.dispatch('wishlist/getShoppingLists');
            this.closeDialog();
        },
        removeUnavailableProductsFromWishlist(wishlist) {
            if (!wishlist.items) {
                return wishlist;
            }

            return {
                ...wishlist,
                items: wishlist.items.filter((wishlistItem) => wishlistItem.product !== null),
            };
        },
    },
};
